import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  alpha,
  useTheme,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useLocales } from 'src/locales';
import Iconify from '../../../../components/iconify/iconify';
import Label from '../../../../components/label/label';
import { LoadingScreen } from '../../../../components/loading-screen';
import { IOSSwitch } from '../../../../components/switch/SwitchIOS';
import { PAGE_SIZES } from '../../../../config-global';
import { useBoolean } from '../../../../hooks/use-boolean';
import { useRouter } from '../../../../routes/hook';
import { BOT_LIST } from '../../../../settings/endpoint';
import makeRequest from '../../../../utils/fetcher';
import { fAmount } from '../../../../utils/format';
import { fDateTime } from '../../../../utils/format-time';
import OrderHistoryTable from '../OrderHistoryTable';

const config = [
  {
    key: 'id',
    label: 'ID',
    render: (data) => (
      <Typography variant="subtitle1" whiteSpace="nowrap">
        #{data.id}
      </Typography>
    ),
  },
  {
    key: 'pair',
    label: 'Pair',
    render: (data) => (
      <Typography variant="body1" whiteSpace="nowrap" textTransform="uppercase">
        {data.base}/{data.quote}
      </Typography>
    ),
  },
  {
    key: 'exchangeType',
    label: 'Exchange',
  },
  {
    key: 'apiKey',
    label: 'API Key',
  },
  {
    key: 'balances',
    label: 'Balances',
    render: (data) => (
      <Stack
        alignItems="flex-start"
        divider={<Divider sx={{ borderStyle: 'dashed', width: '100%' }} />}
      >
        {data.accountInfoObj.balances
          ? data.accountInfoObj.balances.map((item) => (
              <Typography key={item.asset} variant="caption" textTransform="uppercase">
                {fAmount(item.free, 4)} <strong>{item.asset}</strong>
              </Typography>
            ))
          : '--/--'}
      </Stack>
    ),
  },
  {
    key: 'createdTime',
    label: 'Created Time',
    align: 'right',
    render: (data) => (
      <Typography variant="subtitle2" whiteSpace="nowrap">
        {fDateTime(data.createdTime)}
      </Typography>
    ),
  },
];

function BotMakerView() {
  const { t } = useLocales();
  const theme = useTheme();
  const router = useRouter();

  const showInfo = useBoolean();
  const showLogs = useBoolean();
  const showConfirmDelete = useBoolean();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZES[0]);

  const [result, setResult] = useState(null);

  const [selected, setSelected] = useState(null);

  const [errMsg, setErrMsg] = useState('');

  const [isFetching, setIsFetching] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangePageSize = (event) => {
    setPage(0);
    setPageSize(parseInt(event.target.value, 10));
  };

  const getBotList = useCallback(
    (loading = true) => {
      const params = {
        page: page + 1,
        pageSize,
        search: '',
        orderBy: '',
        isDesc: true,
        returnStatistic: false,
        filters: {},
      };
      if (loading) {
        setIsFetching(true);
      }
      setErrMsg('');

      makeRequest().post(
        BOT_LIST,
        params,
        (res) => {
          const newArr = [];

          res.items.forEach((item) => {
            if (item.type === 'MAKER') newArr.push(item);
          });

          res.items = newArr;

          setResult(res);
          setIsFetching(false);
        },
        (err) => {
          setErrMsg(err.msg || err.code);
          setIsFetching(false);
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, pageSize]
  );

  useEffect(() => {
    getBotList();
  }, [getBotList]);

  useEffect(() => {
    const interval = setInterval(() => {
      getBotList(false);
    }, 5000);

    return () => clearInterval(interval);
  }, [getBotList]);

  const handleUpdateStatus = (values) => {
    const params = {
      id: values.id,
      status: values.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
    };

    makeRequest().put(
      '/bot/status',
      params,
      () => {
        getBotList(false);
      },
      () => {}
    );
  };

  const handleDeleteBot = (values) => {
    setIsDeleting(true);
    makeRequest().delete(
      `/bot?id=${values.id}`,
      undefined,
      () => {
        setIsDeleting(false);
        setSelected(null);
        showConfirmDelete.onFalse();
        getBotList(false);
      },
      (err) => {
        setIsDeleting(false);
      }
    );
  };

  const isFirstFetching = result === null && isFetching;
  const isLoading = isFetching && !isFirstFetching;
  const isNotFound = result !== null && result.items.length === 0;

  return (
    <>
      <Stack spacing={6}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h4">{t('lang_062')}</Typography>
          <Button
            size="large"
            variant="contained"
            startIcon={<Iconify icon="ic:round-plus" />}
            onClick={() => {
              router.push('/dashboard/bot/market-makers/new');
            }}
          >
            {t('lang_002')}
          </Button>
        </Stack>

        {/* TABLE */}
        <Stack component={Card}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {config.map((head) => (
                    <TableCell key={head.key} align={head.align || 'left'}>
                      {head.label}
                    </TableCell>
                  ))}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {result &&
                  result.items.map((row) => (
                    <TableRow key={row.id} hover>
                      {config.map((col) => {
                        if (isLoading)
                          return (
                            <TableCell key={col.key}>
                              <Skeleton variant="rounded" width="50%" />
                            </TableCell>
                          );
                        return (
                          <TableCell key={col.key} align={col.align || 'left'}>
                            {col.render ? col.render(row) : row[col.key]}
                          </TableCell>
                        );
                      })}

                      {isLoading ? (
                        <TableCell align="right">
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="flex-end"
                            spacing={1}
                          >
                            <Skeleton variant="circular" width={30} height={30} />

                            <Divider orientation="vertical" flexItem />

                            <Skeleton variant="rounded" width={60} height={30} />
                          </Stack>
                        </TableCell>
                      ) : (
                        <TableCell align="right">
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="flex-end"
                            spacing={1}
                          >
                            {row.status === 'INACTIVE' && Boolean(row.logs) && (
                              <Tooltip
                                title={t('lang_065')}
                                arrow
                                onClick={() => {
                                  showLogs.onTrue();
                                  setSelected(row);
                                }}
                              >
                                <IconButton>
                                  <Iconify icon="ph:warning-duotone" />
                                </IconButton>
                              </Tooltip>
                            )}

                            <Tooltip title={t('lang_064')} arrow>
                              <IconButton
                                onClick={() => {
                                  router.push(`/dashboard/bot/market-makers/edit/${row.id}`);
                                }}
                              >
                                <Iconify icon="material-symbols:edit" />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title={t('lang_063')} arrow>
                              <IconButton
                                onClick={() => {
                                  setSelected(row);
                                  showConfirmDelete.onTrue();
                                }}
                              >
                                <Iconify
                                  icon="icon-park-twotone:delete-five"
                                  sx={{ color: theme.palette.error.main }}
                                />
                              </IconButton>
                            </Tooltip>

                            <Button
                              size="small"
                              variant="outlined"
                              endIcon={<Iconify icon="material-symbols:dashboard-rounded" />}
                              onClick={() => {
                                router.push(
                                  `/dashboard/bot/${row.exchangeType}/${row.base}-${row.quote}/${row.id}`
                                );
                              }}
                            >
                              {t('Dashboard')}
                            </Button>

                            <Button
                              size="small"
                              variant="outlined"
                              endIcon={<Iconify icon="line-md:list-3-twotone" />}
                              onClick={() => {
                                showInfo.onTrue();
                                setSelected(row);
                              }}
                            >
                              {t('')}
                            </Button>

                            <Divider orientation="vertical" flexItem />

                            <Box>
                              <IOSSwitch
                                defaultChecked={row.status === 'ACTIVE'}
                                onChange={() => handleUpdateStatus(row)}
                              />
                            </Box>
                          </Stack>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}

                {isFirstFetching && (
                  <TableRow>
                    <TableCell colSpan={config.length + 1} sx={{ height: 360 }}>
                      <LoadingScreen />
                    </TableCell>
                  </TableRow>
                )}

                {Boolean(errMsg) && (
                  <TableRow>
                    <TableCell
                      colSpan={config.length + 1}
                      sx={{ height: 360, background: theme.palette.error.main }}
                      align="center"
                    >
                      <Iconify
                        icon="nonicons:error-16"
                        sx={{ height: 120, width: 120, opacity: 0.5 }}
                      />
                      <Typography variant="h4" sx={{ color: theme.palette.error.contrastText }}>
                        {errMsg}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}

                {isNotFound && (
                  <TableRow>
                    <TableCell colSpan={config.length + 1} sx={{ height: 360 }} align="center">
                      <Iconify
                        icon="iconoir:file-not-found"
                        sx={{ height: 120, width: 120, opacity: 0.5 }}
                      />
                      <Typography variant="h4" sx={{ color: theme.palette.error.contrastText }}>
                        {t('lang_004')}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={PAGE_SIZES}
            component="div"
            count={result?.itemCount || 0}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangePageSize}
          />
        </Stack>

        <OrderHistoryTable />
      </Stack>

      <Dialog fullWidth open={Boolean(selected) && showLogs.value}>
        <Stack p={4} spacing={2}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Box>
              <Typography variant="h6">
                {t('lang_005')} #{selected?.id}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t('lang_065')}
              </Typography>
            </Box>
            <IconButton
              onClick={() => {
                showLogs.onFalse();
                setSelected(null);
              }}
            >
              <Iconify icon="ic:round-close" />
            </IconButton>
          </Stack>

          <Box
            sx={{
              p: 2,
              border: `1px dashed ${alpha(theme.palette.grey[200], 0.5)}`,
              borderRadius: `${theme.shape.borderRadius}px`,
            }}
          >
            <Typography variant="body2">{selected?.logs || 'Record Not Found.'}</Typography>
          </Box>
        </Stack>
      </Dialog>

      {/* Information */}
      <Dialog fullWidth open={Boolean(selected) && showInfo.value}>
        <Stack p={4} spacing={2}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Box>
              <Typography variant="h6">
                {t('lang_005')} #{selected?.id}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t('lang_007')}
              </Typography>
            </Box>
            <IconButton
              onClick={() => {
                showInfo.onFalse();
                setSelected(null);
              }}
            >
              <Iconify icon="ic:round-close" />
            </IconButton>
          </Stack>

          <Stack spacing={2}>
            {/* balances */}
            {selected?.accountInfoObj?.balances && (
              <Stack
                p={2}
                spacing={1}
                divider={<Divider sx={{ borderStyle: 'dashed' }} />}
                sx={{
                  background: theme.palette.background.neutral,
                  borderRadius: `${theme.shape.borderRadius}px`,
                }}
              >
                {selected?.accountInfoObj?.balances.map((item, index) => (
                  <Stack
                    key={index}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="body2" color="text.secondary">
                      {item.asset}
                    </Typography>
                    <Typography variant="subtitle2">{fAmount(item.free)}</Typography>
                  </Stack>
                ))}
              </Stack>
            )}

            <Stack direction="row">
              <Box
                py={1}
                px={2}
                sx={{
                  background: theme.palette.background.neutral,
                  borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
                }}
              >
                <Typography variant="subtitle1">{t('lang_008')}</Typography>
              </Box>
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                p={1}
                flex={1}
                sx={{
                  border: `2px solid ${theme.palette.background.neutral}`,
                  borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
                }}
              >
                <Label sx={{ width: '100%' }}>{selected?.exchangeInfoObj?.status}</Label>
              </Stack>
            </Stack>

            {/* exchange info */}
            <Stack spacing={2}>
              <Box>
                <Box
                  py={1}
                  px={2}
                  sx={{
                    background: theme.palette.background.neutral,
                    borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
                  }}
                >
                  <Typography variant="subtitle1">{t('lang_009')}</Typography>
                </Box>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  p={1}
                  sx={{
                    border: `2px solid ${theme.palette.background.neutral}`,
                    borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
                  }}
                >
                  {selected?.exchangeInfoObj?.permissions &&
                    selected?.exchangeInfoObj?.permissions.map((item, index) => (
                      <Label key={index} sx={{ width: 'fit-content' }}>
                        {item}
                      </Label>
                    ))}
                </Stack>
              </Box>

              <Box>
                <Box
                  py={1}
                  px={2}
                  sx={{
                    background: theme.palette.background.neutral,
                    borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
                  }}
                >
                  <Typography variant="subtitle1">{t('lang_010')}</Typography>
                </Box>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  p={1}
                  sx={{
                    border: `2px solid ${theme.palette.background.neutral}`,
                    borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
                  }}
                >
                  {selected?.exchangeInfoObj?.orderTypes &&
                    selected?.exchangeInfoObj?.orderTypes.map((item, index) => (
                      <Label key={index} sx={{ width: 'fit-content' }}>
                        {item}
                      </Label>
                    ))}
                </Stack>
              </Box>

              <Stack
                p={2}
                spacing={1}
                divider={<Divider sx={{ borderStyle: 'dashed' }} />}
                sx={{
                  background: theme.palette.background.neutral,
                  borderRadius: `${theme.shape.borderRadius}px`,
                }}
              >
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography variant="body2">{t('lang_011')}:</Typography>
                  <Typography variant="subtitle2">{selected?.exchangeInfoObj?.symbol}</Typography>
                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography variant="body2">{t('lang_012')}:</Typography>
                  <Typography variant="subtitle2">
                    {selected?.exchangeInfoObj?.baseAssetPrecision}
                  </Typography>
                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography variant="body2">{t('lang_013')}:</Typography>
                  <Typography variant="subtitle2">
                    {selected?.exchangeInfoObj?.baseSizePrecision}
                  </Typography>
                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography variant="body2">{t('lang_014')}:</Typography>
                  <Typography variant="subtitle2">
                    {fAmount(selected?.exchangeInfoObj?.quoteAmountPrecision)}
                  </Typography>
                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography variant="body2">{t('lang_015')}:</Typography>
                  <Typography variant="subtitle2">
                    {selected?.exchangeInfoObj?.quoteAssetPrecision}
                  </Typography>
                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography variant="body2">{t('lang_016')}:</Typography>
                  <Typography variant="subtitle2">
                    {selected?.exchangeInfoObj?.quotePrecision}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Dialog>

      <Dialog fullWidth open={Boolean(selected) && showConfirmDelete.value}>
        <DialogTitle>
          {t('lang_005')} #{selected?.id} | {t('lang_017')}
        </DialogTitle>
        <DialogContent>
          <Stack alignItems="center" justifyContent="center" height={100}>
            <Typography variant="h5" fontWeight={400}>
              {t('lang_018')}
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setSelected(null);
              showConfirmDelete.onFalse();
            }}
          >
            {t('lang_019')}
          </Button>
          <LoadingButton
            loading={isDeleting}
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteBot(selected);
            }}
          >
            {t('lang_017')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default BotMakerView;
