import { lazy, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { LoadingScreen } from 'src/components/loading-screen';
import AuthGuard from 'src/contexts/auth/guard/AuthGuard';
import DashboardLayout from 'src/layouts/dashboard';
import DashboardBotPage from 'src/pages/dashboard/DashboardBotPage';

// --------------------------------------------------------------------

const NewBotPage = lazy(() => import('../../pages/dashboard/NewBotPage'));
const EditBotPage = lazy(() => import('../../pages/dashboard/EditBotPage'));

const BotDetailsPage = lazy(() => import('../../pages/dashboard/BotDetailsPage'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <Navigate to="/dashboard/bot/volume-boosters" />, index: true },
      { path: 'bot/:type', element: <DashboardBotPage /> },
      { path: 'bot/:type/new', element: <NewBotPage /> },
      { path: 'bot/:type/edit/:botId', element: <EditBotPage /> },
      { path: 'bot/:exchangeType/:pair/:botId', element: <BotDetailsPage /> },
    ],
  },
];
