import { API } from '../settings/system';
import { getAccessToken, isLoggedIn, logout } from './auth';

const defaultHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export default function makeRequest(accessToken) {
  const myFetch = (method, endpoint, body, successCallback, errorCallback) => {
    const url = API + endpoint;

    body = JSON.stringify(body);

    const headers = defaultHeaders;
    if (isLoggedIn() || accessToken) {
      headers.Authorization = `bearer ${accessToken || getAccessToken()}`;
    }

    let response = null;

    if (body === undefined) {
      response = fetch(url, { method, headers });
    } else {
      response = fetch(url, { method, body, headers });
    }

    responseHandler(response, successCallback, errorCallback);
  };

  const responseHandler = (response, successCallback, errorCallback) => {
    response.then((r) => {
      if (r.status === 200) {
        r.json().then((res) => {
          if (res.success) {
            if (successCallback) successCallback(res.data);
          } else if (errorCallback) errorCallback(res.error);
        });
      } else if (r.status === 403) {
        window.location.href = '403';
      } else if (r.status === 401) {
        console.error('Error: Unauthorized');
        logout();
      } else if (r.status === 500) {
        window.location.href = '500';
      } else if (r.status === 502) {
        console.error('Error: Service unavailable');
      } else if (r.status === 526) {
        console.error('Error: Please connect to VPN');
      } else if (errorCallback) errorCallback('Undefined');
    });
  };

  return {
    get: (endpoint, successCallback, errorCallback) => {
      myFetch('GET', endpoint, undefined, successCallback, errorCallback);
    },
    post: (endpoint, body, successCallback, errorCallback) => {
      myFetch('POST', endpoint, body, successCallback, errorCallback);
    },
    put: (endpoint, body, successCallback, errorCallback) => {
      myFetch('PUT', endpoint, body, successCallback, errorCallback);
    },
    delete: (endpoint, body, successCallback, errorCallback) => {
      myFetch('DELETE', endpoint, body, successCallback, errorCallback);
    },
  };
}
